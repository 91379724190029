@import "../core/Variables";

.bodegon {
  background-color: $color-background;
  display: flex;
  flex-direction: column;

  &__img {
    //   border: 0.5px solid #c4c4c4;

    border-radius: 50px 0 50px 0;
    width: 90%;
    margin: 20px auto;
    max-width: 1100px;
  }
}
