@import "../core/Variables";

.country {
  background-color: $color-background;
  padding: 30px 0;
  @media screen and (min-width: 740px) {
    padding: 30px 0;
    
  }

  &__ul {
    margin-top: 20px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 52px 19px;

    @media screen and (min-width: 740px) {
      flex-wrap:nowrap;
      gap: 20px;
      padding: 0 20px;
      max-width: 900px;
      margin: 70px auto 30px auto;
    }
  }

  &__li {
    margin: 0px auto 0px auto;
    border-radius: 35px 0 35px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    color: #636466;
    width: 165px;
    text-align: center;
    height: 65px;
    padding-top: 50px;
    background-color: white;
    @media screen and (min-width: 740px) {
      height: 80px;
      border-radius: 45px 0 45px 0;
      width: 350px;
      border: 0px;
      justify-content: flex-start;
      padding-top: 40px;
      background-color: white;
      padding-bottom: 50px;
    }
  }

  &__div-img {
    background-color: #f5f5f7ff;
    background-color: white;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0%;
   left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 740px) {
      height: 80px;
      width: 80px;
     
    }
  }

  &__img {
    width: 40px;
    @media screen and (min-width: 740px) {
      width: 52px;
    }
    @media screen and (min-width: 1024px) {
      width: 55px;
    }
  }

  &__number {
    color: black;
    margin: 0px auto 0 auto;
    font-weight: 600;
    font-size: 42px;
    @media screen and (min-width: 740px) {
      font-size: 50px;
    }
    @media screen and (min-width: 1024px) {
      //  font-size: 20px;
    }
  }

  &__number--span {
    font-size: 25px;
    @media screen and (min-width: 740px) {
      font-size: 25px;
    }
  }

  &__subtitle {
    max-width: 105px;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    color: black;
    @media screen and (min-width: 740px) {
      font-size: 18px;
      max-width: 138px;
    }
  }
}
