@import "../core/Variables";

.video {
  background-color: $color-white;
  padding-bottom: 20px;
  @media screen and (min-width: 740px) {
    padding-bottom: 70px;
  }
  @media screen and (min-width: 1024px) {
    padding-bottom: 100px;
  }

  &__article {
    position: relative;
    padding: 10px;
    height: 300px;
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    @media screen and (min-width: 740px) {
      height: 400px;
    }
    @media screen and (min-width: 1024px) {
      height: 600px;
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
