@import "../core/Variables";

// ============ HEADER ==========

.header {
  position: fixed; /* Esto fija el header en la parte superior de la pantalla */
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-bottom: 0.1px solid rgb(218, 218, 218);
  background-color: $color-background;
  justify-content: space-between;
  width: 100vw;
  height: 70px;
  z-index: 3;

  &__logo {
    width: 140px;
    margin-left: 20px;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border: 2px solid #63646687;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    @media screen and (min-width: 740px) {
      display: none;
    }
  }

  &__btn-line {
    width: 20px;
    height: 3px;
    background-color: $color-dark-grey;
    margin: 3px 0;
    transition: 0.4s;
  }

  &__nav {
    display: none;
    position: absolute;
    background-color: #00000075;
    width: 100vw;
    float: right;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    transition: display 10s ease;
    z-index: 3;
    @media screen and (min-width: 740px) {
      display: flex;
      margin-left: 15px;
      position: static;
      background-color: transparent;
      height: 0px;
    }
  }

  &__ul {
    z-index: 5;
    background-color: #f7f7f7f5;
    width: 60%;
    height: calc(100vh - 98px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 28px 0 0 25px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    @media screen and (min-width: 740px) {
      margin-left: 20px;
      //  justify-content: end;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      width: 70%;
      height: 0px;
      padding: 0;
    }
  }

  &__li {
    @media screen and (min-width: 740px) {
    width: 400px;
    text-align: start;
  }
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Fira Sans", sans-serif;
    color: $color-dark-grey;
    font-weight: 600;
    font-size: 16px;
    position: relative;

    // @media screen and (min-width: 740px) {
    //   width: 800px;}

    &::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 1px;
      height: 2px;
      background-color: $color-background;
      transition: width 0.5s ease; /* Transición para el ancho */
    }

    &:hover::before {
      background-color: $color-red;
      width: 100%; /* Cambia el ancho al 100% en hover */
    }

    &:hover {
      color: $color-red; /* Cambia el color a tu preferencia */
      transition: color 0.5s ease; /* Añade una transición suave */
    }
  }

  &__arrow {
    font-size: 14px;
    margin-left: 5px;
    font-weight: 600;
    transition: transform 0.3s;
  }
}

//  ============ ABIR NAV ==========
.open {
  display: block;
  transition: display 10s ease;
}

// ============  MENU HAMBURGUESA X ==============

.header__btn {
  &.menu-open {
    .line-one {
      transition: transform 0.5s ease;
      transform: rotate(45deg) translate(7px, 6px);
    }
    .line-two {
      transition: transform 0.5s ease;
      opacity: 0;
    }
    .line-three {
      transition: transform 0.5s ease;
      transform: rotate(-45deg) translate(7px, -6px);
    }
  }
}

//  ============ SUBMENU  =========================

.submenu {
  position: relative;

  // abre el menu
  &:hover .submenu__ul {
    display: block;
    transition: display 0.1s ease;
  }
  // cambia la flechita
  &:hover .header__arrow {
    transform: rotate(90deg);
    transition: transform 0.1s ease;
  }

  &__ul {
    display: none;
    padding: 10px 0 0 0;
    transition: display 10s ease;
    text-align: start;
    @media screen and (min-width: 740px) {
      z-index: 1000;
      width: 200px;
      position: absolute;
      top: 20px;
      left: 0;
      background-color: #f7f7f7;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }
  &__li {
    margin: 5px;
  }

  &__link {
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    color: $color-dark-grey;
    font-weight: 600;
    font-size: 16px;
  }
}

//  ============ TRANFORM FLECHA SUBMENU ==============
.open header__arrow {
  transform: rotate(90deg);
}

// ============ ACTIVO NAVBAR ====================

.active {
  color: rgba(99, 100, 102, 0.715);
}
// ============ ANIMACIONES DEL NAV ========================

//Se activa solo en mobile la animación de salir
@media screen and (max-width: 740px) {
  .animated-nav {
    transition: all 1s;
    animation: showNav 0.5s;
  }

  .exit-nav {
    transition: all 2s;
    animation: exitNav 1s;
  }
}

@keyframes showNav {
  0% {
    opacity: 0;
    transform: translateX(-50px);
    /* Desplazar hacia la izquierda 50px */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitNav {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50px);
    /* Desplazar hacia la izquierda 50px */
  }
}
