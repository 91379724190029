:root {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Fira Sans", sans-serif;
}

@import "core/variables";
@import "core/reset";
// @import "layout/header";
// @import "layout/main";
// @import "layout/footer";
