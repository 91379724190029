@import "../core/Variables";

/* * ============ SERVICES ========== */

.table {
  background-color: white;
  padding-bottom: 50px;

  &__ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    gap: 18px;
  }

  &__li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  &__li-img {
    margin-right: 5px;
    width: 25px;
    @media screen and (min-width: 740px) {
      width: 40px;
    }
  }

  &__table {
    max-width: 900px;
    border-collapse: separate;
    border-spacing: 2px;
    width: 95%;
    margin: 20px auto 0 auto;
    @media screen and (min-width: 740px) {
      margin: 40px auto 0 auto;
    }
  }

  &__logo {
    width: 88%;
    max-width: 100px;
    
  }

  &__icon {
    width: 25px;
    @media screen and (min-width: 740px) {
      width: 40px;
    }
  }

  &__colum-title {
    width: 90%;
    line-height: 16px;
    color: #303030;
    font-size: 15px;
   margin-bottom: 10px;
    font-weight: 400;
  }

  &__arrow {
    transition: transform 0.5s ease;
    width: 10px;
    height: 10px;
    /* margin-left: 18px; */
    font-size: 14px;
    color: red;
    @media screen and (min-width: 740px) {
      width: 15px;
      font-size: 16px;
      height: 15px;
    }
  }
  
  &__colum-p {
    transition:  display 0.5s ease-in-out; 
     display: none;
    width: 95%;
   // height: auto; 
    color: $color-dark-grey;
    font-weight: 200;
    font-size: 14px;
    line-height: 1.3;
    margin-top: 5px;
  } 
&__colum-p-span{
  line-height: 0.8;
    font-size: 10px;
  }

   &__colum {
    cursor: pointer;
    width: 96%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: start;
    padding: 15px 0px 20px 5px;
   max-height: 55px;
    overflow: hidden;
  transition: max-height 1.2s ease; 
  @media screen and (min-width: 740px) {
    max-height: 40px;
    transition: max-height 1.2s ease; 
  }

  }
  
  &__fila {
    border-bottom: 1px solid #c4c4c48f;
    display: grid;
    grid-template-columns: 70% 16% 14%;
    align-items: end;
    @media screen and (min-width: 740px) {
      grid-template-columns: 60% 25% 15%;
    }
  }



  &__colum-two,
  &__colum-three {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__colum-two {
    background-color: #e3e3e3;
    box-shadow: 2px 5px 6px #0000004d;
    z-index: 1;
  }

  &__colum-three {
    background-color: #f7f7f7;
  }
}

// columnas logos

.colum-one {
  z-index: 1;
  height: 60px;
  border-radius: 20px 0 0 0;
  @media screen and (min-width: 740px) {
    height: 100px;
    border-radius: 30px 0 0 0;
  }
}

.colum-two {
  height: 50px;
}

// columnas PARES / IMPARES

.odd {
  background-color: #f7f7f7c4;
}


/* * ============ ANIMACIÓN ========== */
/* * ============================== */


@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.open-column {
  max-height: 180px; 
 // height: 55px;
  @media screen and (min-width: 740px) {
    max-height: 110px; 
  }
}


.open-arrow {
  transform: rotate(90deg);
}

.open-p {

       animation: slideInFromRight 0.6s ease-in-out forwards; /* Aplica la animación de aparición desde la derecha */
        position: relative;
        display: block; 

}