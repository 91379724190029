@import "../core/Variables";

.guardian {
  background-color: $color-background;
  padding-bottom: 70px;

  &__section {
    display: flex;
    margin: -74px 0px 0 0px;
    flex-direction: column;
    align-items: center;
    transition: scale 0.5s ease;
    text-align: center;
    @media screen and (min-width: 740px) {
      flex-direction: row;
      align-items: flex-start;
      max-width: 660px;
      padding: 0 30px 0 0px;
      margin: 24px auto 20px auto;
      border-radius: 40px 0 40px 0;
      //   border: 1px solid #c4c4c4;
      background-color: white;
    }
    @media screen and (min-width: 1024px) {
      max-width: 800px;
      padding: 10px 0px 20px 29px;
    }
  }

  &__img {
    width: 78%;
    height: 320px;
    border-radius: 40px 0 40px 0;
    z-index: 2;
    object-fit: cover;
    object-position: 40% 80%;
    transform: translateY(90px);
    max-width: 370px;
    @media screen and (min-width: 740px) {
      object-position: center center;
      width: 41%;
      height: 254px;
      border-radius: 32px 0 0px 0;
      transform: translate(30px, 27px);
      z-index: 1;
    }
    @media screen and (min-width: 1024px) {
      width: 40%;
      left: -64px;
    top: 177px;
    height: 287px;
    }
  }

  &__article {
    margin-top: 12px;
    border-radius: 40px 0 40px 0;
    background-color: white;
    border: 1px solid $color-bronw;
    width: 95%;
    max-width: 473px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-dark-grey;
    padding: 85px 0 50px 0;
    @media screen and (min-width: 740px) {
      border-radius: 0px 0 45px 0;
      max-width: 673px;
      margin-top: 0px;
      justify-content: center;
      align-items: flex-end;
      flex-wrap: wrap;
      padding: 0 0 20px 0;
      background-color: transparent;
      border: 0px solid $color-bronw;
    }
    @media screen and (min-width: 1024px) {
      margin-right: 32px;
    }
  }

  &__img-icon {
    z-index: 2;
    object-fit: cover;
    position: absolute;
    top: -172px;
    height: 281px;
    left: 10px;
    &:hover {
      scale: 110%;
    }
    @media screen and (min-width: 740px) {
      left: -64px;
      top: 193px;
      height: 281px;
    }
  }

  &__subtitle {
    line-height: 22px;
    max-width: 270px;
    margin: 30px auto 15px auto;
    color: black;
    font-weight: 500;
    font-size: 20px;
    @media screen and (min-width: 1024px) {
      max-width: 300px;
    }
  }

  &__hr {
    width: 40px;
    border: none;
    height: 2px;
    background-color: $color-red;
    margin: 5px auto;
  }

  &__paragrahp {
    line-height: 18px;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 40px;
    width: 90%;
    margin: 10px auto 10px auto;
    max-width: 337px;

    @media screen and (min-width: 740px) {
      max-width: 296px;
      line-height: 16px;
      padding: 0 15px;
      font-size: 14px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 360px;
    }
  }

  &__ul {
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    @media screen and (min-width: 740px) {
      margin: 20px auto 0 100px;
    }
  }

  &__li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: start;
  }

  &__icon {
    width: 60px;
    margin-right: 10px;
    &:hover {
      scale: 110%;
    }
  }

  &__text {
    color: black;
    font-size: 12px;
    line-height: 15px;
    width: 173px;
    margin: 0 auto;
  }
}
