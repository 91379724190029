@import "../core/Variables";

.cerradura {
  background-color: $color-background;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 740px) {
    padding-bottom: 10px;
  }
  @media screen and (min-width: 1024px) {
    padding-bottom: 20px;
  }


  &__iframe {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}