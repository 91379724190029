@import "../core/Variables";

.mobile {
  background-color: $color-bronw;
  padding: 30px 0;

  &__section {
    @media screen and (min-width: 740px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &__article {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__paragraph {
    color: $color-dark-grey;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    max-width: 320px;
    margin: 0 auto 20px auto;
    @media screen and (min-width: 740px) {
      max-width: 360px;
      font-size: 18px;
      margin: 0 auto 30px auto;
    }
  }

  &__div-app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 120px;
    margin: 0 5px 10px 5px;
    @media screen and (min-width: 740px) {
      margin: 0 5px 10px 5px;
      width: 140px;
    }
  }

  &__div-img {
    background-image: url(../../img/mobile/mobile.png);
    background-position: center;
    background-size: cover;
    display: flex;
    height: 670px;
    width: 300px;
    margin: 0 auto;
  }

  &__iframe {
    border-radius: 25px;
    margin: auto auto;
    height: 577px;
    width: 286px;
  }
}
