@import "../core/Variables";

/* * ============ SERVICES ========== */

.service {
  text-align: center;
  background-color: $color-background;
  padding: 0 0 30px 0;

  &__section {
    display: flex;
    margin: 30px 10px;
    flex-direction: column;
    align-items: center;
    transition: scale 0.5s ease;
    @media screen and (min-width: 740px) {
      flex-direction: row;
      justify-content: space-around;
      height: 217px;
      max-width: 1024px;
      margin: 31px auto;
      padding: 0 30px;
    }
    @media screen and (min-width: 1024px) {
      height: 280px;
    }
    &:hover {
      scale: 103%;
    }
  }

  &__img {
    width: 95%;
    height: 230px;
    border-radius: 40px 0 0px 0;
    object-fit: cover;
    object-position: 50% 24%;
    box-shadow: 0px 2px 10px #0000004d;
    @media screen and (min-width: 740px) {
      width: 50%;
      height: 100%;
      border-radius: 45px 0 0px 0;
    }
  }

  &__article {
    margin-top: 12px;
    border-radius: 0px 0 40px 0;
    background-color: white;
    border: 1px solid #c4c4c4;
    width: 95%;
    box-shadow: 0px 2px 10px #00000015;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-dark-grey;
    @media screen and (min-width: 740px) {
      border-radius: 0px 0 45px 0;
      margin-top: 0px;
      width: 50%;
      height: 100%;
    }
  }

  &__img-icon {
    position: absolute;
    top: -33px;
    left: 9px;
    width: 55px;
    height: 55px;
    left: 9px;
    background-color: white;
    box-shadow: 0px 0px 5px 1px #00000015;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    @media screen and (min-width: 740px) {
      left: auto;
      right: 10px;
      top: -27px;
      width: 60px;
      height: 60px;
    }
  }

  &__subtitle {
    line-height: 22px;
    max-width: 270px;
    margin: 30px auto 15px auto;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 20px;
    @media screen and (min-width: 1024px) {
      max-width: 300px;
    }
  }

  &__hr {
    width: 40px;
    border: none;
    height: 2px;
    background-color: $color-red;
    margin: 5px auto;
  }

  &__paragrahp {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 40px;
    width: 90%;
    margin: 10px auto 35px auto;

    @media screen and (min-width: 740px) {
      max-width: 300px;
      padding: 0 15px;
      font-size: 14px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
  }
}
