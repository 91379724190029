@import "../core/Variables";

.title {
  padding: 20px 0;
  text-align: center;

  &__title {
    margin: 30px auto 20px auto;
    font-size: 32px;
    color: $color-dark-grey;
    display: flex;
    flex-direction: column;
    font-weight: 800;
    padding: 0 10px;
    @media screen and (min-width: 740px) {
      font-size: 40px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 40px;
    }
  }

  &__span {
    margin-top: 5px;
    color: black;
    font-weight: 600;
    font-size: 26px;
    @media screen and (min-width: 740px) {
      font-size: 30px;
    }
  }

  &__paragraph {
    color: $color-dark-grey;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    max-width: 320px;
    margin: 0 auto;
    @media screen and (min-width: 740px) {
      max-width: 450px;
      font-size: 16px;
    }
    @media screen and (min-width: 740px) {
      max-width: 600px;
      font-size: 18px;
    }
  }
}
