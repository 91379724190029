// Esta es la variable que usaremos como tamaño por defecto
$defaultFontSize: 16;

// Estos son los tamaños para las media-queries

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

// fonts
$font-main: "Fira Sans", sans-serif;

// colors
$color-red: rgb(255, 0, 51);
$color-bronw: rgb(215, 210, 204);
$color-dark-grey: rgb(99, 100, 102);
$color-white: rgb(255, 255, 255);
$color-black: #323232;
$color-title: #444;



$color-background:  #f5f5f7ff;
//$color-background:  #f7f7f7;

img {
    pointer-events: none;
    }