@import "../core/Variables";

.buttonopen {
  cursor: pointer;
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 113px;
  height: 92px;
  border-top-left-radius: 293px;
  background-color: $color-red;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  &:hover {
    transform: scale(110%);
    transition: all 0.5s;
    border: 5px solid white;
  }

  @media screen and (min-width: 740px) {
    width: 162px;
    height: 139px;
    border-top-left-radius: 245px;
  }

  &__icon {
    width: 35px;
    display: block;
    margin-bottom: 8px;
    margin-right: 19px;
    &:hover {
      transform: translateY(-5px); /* Mueve el elemento 5px hacia arriba */
      transition: transform 0.3s;
    }

    @media screen and (min-width: 740px) {
      width: 47px;
      display: block;
      margin-bottom: 9px;
      margin-right: 30px;
    }
  }

  &__title {
    font-size: 14px;
    text-align: end;
    font-weight: 700;
    margin: 0px 10px 7px 0;
    @media screen and (min-width: 740px) {
      margin: 0 15px 20px 0;
      font-size: 20px;
    }
  }
}

.custom-modal {
  background-color: aqua;
}

/* * ============================== */
/* * ============ MODAL FORM ========== */
/* * ============================== */

.modal {
  position: fixed;
  z-index: 15;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.61);
  overflow: auto;
  display: flex;

  &__white {
    background-color: $color-white;
    border-radius: 30px 0 30px 0;
    margin: auto auto;
    width: 65%;
    height: 300px;
    padding: 20px 30px;
    position: relative;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 740px) {
      border-radius: 40px 0 40px 0;
      width: 60%;
    }
    @media screen and (min-width: 1024px) {
      width: 50%;
      max-width: 800px;
    }
  }

  &__close {
    background-color: white;
    border: 1px solid $color-red;
    color: $color-red;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    margin-bottom: 30px;
    position: absolute;
    right: 5px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $color-red;
      color: white;
      transition: all 0.5s;
    }
  }

  &__close-icon {
    font-weight: 600;
    font-size: 16px;
  }

  &__ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  &__li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__link {
    text-decoration: none;
    color: $color-dark-grey;
    font-weight: 400;
    font-size: 14px;
    padding: 0 5px;
    width: 90%;

    @media screen and (min-width: 740px) {
      font-size: 18px;
    }

    &:hover {
      transition: color 0.3s;
      color: $color-red;
    }
  }

  &__icon {
    width: 40px;
    @media screen and (min-width: 740px) {
      width: 50px;
    }
  }

  &__logo {
    margin-top: 50px;
    width: 110px;
  }
}


/* * ============================== */
/* * ============ ANIMACIÓN ========== */
/* * ============================== */

  .modal-in{
    transition: all 1s;
    animation: modalIn  0.4s ease-in-out forwards;
  }

  .modal-exit {
    transition: all 2s;
    animation: modalExit   0.4s ease-in-out forwards;
 //   animation: modalExit 1s;
  }


@keyframes modalIn {
  0% {
    opacity: 0;
    transform: translateY(+50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalExit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(+50px);
  }
}


