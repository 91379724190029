@import "../core/Variables";

.includes {
  background-color: $color-background;
  background-color: white;
  padding-bottom: 50px;

  &__ul {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (min-width: 740px) {
      flex-direction: row;
      gap: 40px;
      flex-wrap: wrap;
      padding: 0 20px;
      max-width: 755px;
      margin: 50px auto;
    }
  }

  &__li {
    border: 1px solid #c4c4c4;
    margin: 0px auto 0px auto;
    border-radius: 0px 0 45px 0;
    // background-color: white;
    // box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
    color: $color-dark-grey;
    width: 100%;
    max-width: 310px;
    text-align: center;
    height: 50px;
    @media screen and (min-width: 740px) {
      height: 80px;
      border-radius: 45px 0 45px 0;
      width: 350px;
    }
  }

  &__div-img {
    background-color: #f5f5f7ff;
    background-color: white;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(-50%, -50%);
  }

  &__img {
    width: 52px;
  }

  &__subtitle {
    color: #444;
    //  color: $color-dark-grey;
    line-height: 20px;
    width: 75%;
    max-width: 230px;
    margin: 0px auto 0 auto;
    font-weight: 600;
    font-size: 14px;
    @media screen and (min-width: 740px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1024px) {
      //  font-size: 20px;
    }
  }
}
