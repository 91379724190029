@import "../core/Variables";

.function-alarm {
  background-color: $color-background;
  padding: 30px 0 80px 0;
  @media screen and (min-width: 740px) {
    padding: 30px 0 100px 0;
  }

  &__section {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 740px) {
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      padding: 0 20px;
      max-width: 820px;
      margin: 0 auto;
    }
    @media screen and (min-width: 1024px) {
      max-width: 1200px;
      gap: 10px;
    }
  }

  &__article {
    border: 1px solid #c4c4c4;
    margin: 31px auto 0px auto;
    border-radius: 45px 0 45px 0;
    background-color: rgba(255, 255, 255, 0.9);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
    color: $color-dark-grey;
    width: 100%;
    max-width: 400px;
    text-align: center;
    @media screen and (min-width: 740px) {
      width: 350px;
      padding: 30px 0 40px 0;
    }
    @media screen and (min-width: 1024px) {
      width: 320px;
      padding: 30px 0 60px 0;
    }
  }

  &__img {
    border: 1px solid #c4c4c4;
    position: absolute;
    top: -34px;
    right: 16px;
    width: 50px;
    height: 50px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
  }

  &__subtitle {
    color: #444;
    line-height: 22px;
    max-width: 250px;
    margin: 30px auto 0 auto;
    font-weight: 500;
    font-size: 20px;
  }

  &__hr {
    width: 40px;
    border: none;
    height: 2px;
    background-color: $color-red;
    margin: 20px auto;
  }

  &__paragrahp {
    line-height: 20px;
    color: $color-dark-grey;
    font-weight: 300;
    margin-bottom: 40px;
    max-width: 300px;
    margin: 0 auto;
    font-size: 14px;
    @media screen and (min-width: 740px) {
      max-width: 260px;
      padding: 0 15px;
      font-size: 14px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
  }
}
