@import "../core/Variables";

/* * ============ HERO ========== */

.hero {
  background-image: url(../../img/hero.jpeg);
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom right;
  color: white;
  text-align: center;
  padding: 80px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  @media screen and (min-width: 740px) {
    padding: 90px 50px 80px 50px;
  }

  &__title {
    letter-spacing: 1px;
    position: relative;
    z-index: 1;
    margin: 100px auto 20px auto;
    font-size: 20px;
    display: flex;
    text-transform: uppercase;
    flex-direction: column;
    font-weight: 600;
    padding: 0 10px;
    max-width: 380px;
    @media screen and (min-width: 740px) {
      font-size: 28px;
      margin-top: 60px;
      max-width: 560px;
    }
    @media screen and (min-width: 1024px) {
      margin-top: 40px;
      font-size: 30px;
      max-width: 600px;
    }
  }

  &__subtitle {
    font-weight: 400;
    line-height: 18px;
    font-size: 16px;
    margin: 20px auto 0px auto;
    padding: 0 20px;

    @media screen and (min-width: 740px) {
      width: 550px;
      font-size: 16px;
    }
  }

  &__img {
    background-color: white;
    border-radius: 20px 0 20px 0;
    padding: 10px 10px;
    width: 140px;
  }

  &__article {
    position: relative;
    padding: 10px;
    margin: 20px auto 0 auto;
    border-radius: 30px 0 30px 0;
    background-color: #f5f5f7eb;
    border: 2px solid $color-red;
    
    @media screen and (min-width: 740px) {
      width: 600px;
    }
    @media screen and (min-width: 1024px) {
      width: 650px;
    }
  }

  &__article-title {
    letter-spacing: 2px;
    margin: 10px auto 10px auto;
    line-height: 1.2;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0 10px;
    max-width: 300px;
    color: $color-red;
    @media screen and (min-width: 740px) {
      font-size: 20px;
    }
  }


  &__article-img {
    object-fit: cover;
    width: 100%;
    @media screen and (min-width: 740px) {
      width: 80%;
    }
    @media screen and (min-width: 1024px) {
      width: 70%;
    }
  }

  &__article-button {
    text-decoration: none;
    background-color: #dcd5cc;
    border: 1px solid $color-dark-grey;
    border-radius: 20px;
    padding: 6px 9px;
    color: black;
    display: block;
    position: absolute;
    top: 26%;
    right: 10%;
    font-size: 14px;
    box-shadow: 1px 1px 2px #00000054;
    transition: transform 0.3s ease;
    @media screen and (min-width: 740px) {
      padding: 10px 20px;
      top: 28%;
      right: 18%;
    }
    @media screen and (min-width: 1024px) {
      top: 35%;
      right: 20%;
    }

    &:hover {
      transform: scale(110%);
      .hero__article-icon {
      transform: translateX(2px);
    }
    }
    
  }

  &__article-icon {
    transition: transform 0.3s ease;
    margin-left: 9px;
  }

  &__article-new {
    position: absolute;
    background-color: #60b166;
    color: #fff;
    padding: 4px 9px;
    border-radius: 15px 15px 15px 0;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    margin: 0 auto;
    width: fit-content;
    top: 40%;
    right: 10%;
    @media screen and (min-width: 740px) {
      padding: 6px 13px;
      top: 9%;
      right: 80%;
    }
  }
}
